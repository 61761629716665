@import "node_modules/normalize.scss/normalize";
@import "https://cdnjs.cloudflare.com/ajax/libs/jcf/1.2.3/css/theme-minimal/jcf.css";
@import "libs/swiper.min.css";
@import "parts/footer";
@import "parts/media";

* {
    box-sizing: border-box;
    font-family: "Roboto Slab", Arial, sans-serif;
}

html {
    height: 100%;
    width: 100%;
    font-size: 10px;
}

body {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
    background: #27323b;
    font-family: "Roboto Slab", Arial, sans-serif;
    color: #fff
}

img {
    width: 100%;
    height: auto;
    max-width: 100%;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 90px;

    &._second {
        padding-top: 30px;
        min-height: auto;
    }

    @include mobile {
        padding-top: 70px;
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2px 20px;
    z-index: 120;
    background-color: #27323b;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    box-shadow: 0 0 7px rgba(0, 0, 0, 1);

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left {
            @include tablet {
                position: fixed;
                left: 0;
                top: 0;
                background: #2c343a;
                height: 100vh;
                overflow: auto;
                width: 300px;
                z-index: 99;
                box-shadow: 5px 0 10px rgba(0,0,0,.3);
                transition: all .3s ease;
                transform: translateX(-110%);

                &.active {
                    transform: translateX(0);
                }
            }
            & > div {
                & > ul {
                    list-style-type: none;
                    display: flex;
                    margin: 0;
                    padding: 0;

                    @include tablet {
                        flex-direction: column;
                        padding-top: 3rem;
                    }

                    & > li {
                        position: relative;

                        @include tablet {
                            border-bottom: 1px solid #eaeaea;
                        }

                        &:hover {
                            & > a {
                                background-color: #1b2c34
                            }

                            & > ul {
                                opacity: 1;
                                pointer-events: auto;
                            }
                        }
                        &.menu-item-has-children{
                            & > a{
                                @include mobile {
                                    position: relative;
                                    width: 100%;

                                    &:after{
                                        content: '';
                                        width: 18px;
                                        height: 18px;
                                        background: url(img/arrow-left.svg) no-repeat center center;
                                        background-size: contain;
                                        position: absolute;
                                        right: 10px;
                                        top: calc(50% - 9px);
                                        display: inline-block;
                                        transform: rotateY(-180deg);
                                    }
                                }
                            }

                             li.menu-item-has-children > a{
                                @include mobile {
                                    position: relative;
                                    width: 100%;

                                    &:after{
                                        content: '';
                                        width: 18px;
                                        height: 18px;
                                        background: url(img/arrow-left.svg) no-repeat center center;
                                        background-size: contain;
                                        position: absolute;
                                        right: 10px;
                                        top: calc(50% - 9px);
                                        display: inline-block;
                                        transform: rotateY(-180deg);
                                    }
                                }
                            }

                        }
                        & > a {
                            padding: 14px 30px;
                            display: block;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 22px;
                            letter-spacing: .1em;
                            text-transform: capitalize;
                            border-radius: 10px;
                            transition: background-color .5s ease;
                            font-size: 18px;
                            text-decoration: none;
                            color: white;
                        }

                        ul {
                            width: 220px;
                            opacity: 0;
                            list-style-type: none;
                            padding: 0;
                            position: absolute;
                            left: 0;
                            top: calc(100% + 10px);
                            background-color: #4c5e6b;
                            font-size: 14px;
                            pointer-events: none;

                            @include tablet {
                                width: auto;
                                display: none;
                            }

                            @include mobile {
                                display: none;
                                position: relative;
                                opacity: 1;
                                top: auto;
                                left: auto;
                                padding-left: 40px;
                            }

                            &.active {
                                @include mobile {
                                    display: block;
                                }
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                height: 10px;
                                width: 100%;
                                left: 0;
                                bottom: 100%;
                            }

                            & > li {
                                position: relative;

                                &:hover {
                                    & > a {
                                        background-color: #1b2c34
                                    }

                                    & > ul {
                                        opacity: 1;
                                        pointer-events: auto;
                                    }
                                }

                                & > ul {
                                    left: 100%;
                                    top: 0;
                                    pointer-events: none;

                                    @include mobile {
                                        left: auto;
                                        padding-left: 40px;
                                        top: 0;
                                    }

                                }

                                & > a {
                                    width: 100%;
                                    display: inline-block;
                                    color: white;
                                    text-decoration: none;
                                    padding: 10px 15px;
                                    white-space: nowrap;

                                    &:hover {
                                        color: #35e2c5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__social-box {
        display: block;
        min-width: 200px;

        @include mobile {
            display: none;
        }
    }

    &__social-box &__social-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    &__social-box &__social-icon__twitter {
        background: url(img/social/icon-twitter.png) no-repeat center center
    }

    &__social-box &__social-icon__google {
        background: url(img/social/icon-google.png) no-repeat center center
    }

    &__social-box &__social-icon__facebook {
        background: url(img/social/icon-facebook.png) no-repeat center center
    }

    &__social-box &__social-icon__tumbler {
        background: url(img/social/icon-tumbler.png) no-repeat center center
    }

    &__social-box &__social-icon__item {
        display: block;
        width: 30px;
        height: 30px;
        margin: 0 5px;
        border-radius: 100%;
        -webkit-transition: .35s;
        transition: .35s
    }

    &__social-box &__social-icon__item:first-child {
        margin-left: 0
    }

    &__social-box &__social-icon__item:hover {
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 5px;
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    .logo__image {
        display: block;
        width: 116px;
        margin-top: 10px
    }

    &__nav-login {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    &__nav-login .btn-secondary {
        margin: 5px
    }

    &__nav-login .btn {
        margin: 0 20px;

        @include mobile {
            margin: 0 5px 0 15px;
        }
    }

    &__logout {
        margin-left: 15px;
        min-width: 20px
    }
}

.btn {
    display: inline-block;
    background-color: #d45a5f;
    padding: 12px 30px;
    margin: 15px auto;
    border: none;
    -webkit-transition: .35s;
    transition: .35s;
    color: #fff;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: "Roboto Slab";
    cursor: pointer;
    overflow: hidden;
    outline: 0;
    border-radius: 10px;
    font-size: 14px;

    &._small {
        padding: 8px 16px;
        font-size: 12px;
    }

    @include mobile {
        padding: 8px 8px;
    }
}

.loader-wrapper {
    text-align: center;
}

.btn:hover {
    -webkit-box-shadow: -.3px -.3px 10px #d45a5f;
    box-shadow: -.3px -.3px 10px #d45a5f
}

.btn:active {
    background-color: #d3363d;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-cashbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 45px;
    text-transform: uppercase;
    border: 2px solid #e44f5b;
    -webkit-transition: .35s;
    transition: .35s;
    letter-spacing: -.4px;
    line-height: 5.6rem;
    color: #e44f5b;
    font-size: 2.8rem
}

.btn-cashbox:hover {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-disabled {
    display: inline-block;
    margin: 15px auto;
    padding: 12px 30px;
    background: #a8a8a8;
    color: #5b5b5a;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;;
    border-radius: 10px;
    font-size: 1.4rem
}

.btn-disabled:hover {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-green {
    background-color: #39b54a
}

.btn-secondary {
    display: inline-block;
    margin: 15px auto;
    padding: 12px 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;;
    text-align: center;
    cursor: pointer;
    -webkit-transition: .35s;
    transition: .35s;
    font-size: 1.4rem;

    @include mobile {
        padding: 6px 8px;
    }
}

.btn-secondary:hover {
    -webkit-box-shadow: -.3px -.3px 10px #fff;
    box-shadow: -.3px -.3px 10px #fff
}

.btn-secondary:active {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #fff;
    color: #000
}

.btn-back,
.btn-cancel {
    display: inline-block;
    margin: 15px auto;
    padding: 12px 30px;
    border: 2px solid #d45a5f;
    color: #d45a5f;
    background-color: transparent;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;;
    cursor: pointer;
    -webkit-transition: .35s;
    transition: .35s;
    font-size: 1.4rem
}

.btn-back-neon,
.btn-cancel-neon {
    color: #67d0c6;
    cursor: pointer
}

.btn-back-neon img,
.btn-cancel-neon img {
    width: 7px;
    height: 10px;
    margin-right: 5px
}

.btn-back-neon:hover,
.btn-cancel-neon:hover {
    text-decoration: underline
}

.btn-back:hover,
.btn-cancel:hover {
    -webkit-box-shadow: -.3px -.3px 10px #d45a5f;
    box-shadow: -.3px -.3px 10px #d45a5f
}

.btn-back:active,
.btn-cancel:active {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #d45a5f
}

.btn-neon,
.cash-btn-form {
    display: inline-block;
    margin: 15px auto;
    padding: 12px 30px;
    color: #fff;
    background-color: #67d0c6;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;
    cursor: pointer;
    -webkit-transition: .35s;
    transition: .35s;
    font-size: 14px;
    font-size: 1.4rem
}

.btn-neon:hover,
.cash-btn-form:hover {
    -webkit-box-shadow: -.3px -.3px 10px #67d0c6;
    box-shadow: -.3px -.3px 10px #67d0c6
}

.btn-neon:active,
.cash-btn-form:active {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #52b9af
}

form.search, div.search {
    width: 220px;
    position: relative;
    background: #4c5e6b;
    -webkit-transition: .35s;
    transition: .35s;
    border-radius: 5px;
    margin-bottom: 16px;
    margin-left: 3rem;
    margin-bottom: 0;

    @include tablet {
        margin-left: 0;
    }
}

form.search:hover, div.search:hover {
    -webkit-box-shadow: 0 3px 12px -3px #000;
    box-shadow: 0 3px 12px -3px #000
}

form.search:hover::after,
form.search:hover::before,
div.search:hover::after,
div.search:hover::before{
    content: "";
    display: block;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .75);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .75)
}

.search__input {
    width: 100%;
    height: 40px;
    border: none;
    color: #c2c2c2;
    padding: 0 20px 0 60px;
    background: 0 0;
    letter-spacing: 1.2px;
    font-size: 14px;
    font-size: 1.4rem
}

@media (min-width: 768px) {
    .search__input {
        width: 220px;
        height: 50px;
        padding: 0 20px 0 60px
    }
}

.search__input::-webkit-input-placeholder {
    opacity: 1;
    color: #c2c2c2;
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 1.2px
}

.search__input::-moz-placeholder {
    opacity: 1;
    color: #c2c2c2;
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 1.2px
}

.search__input:-ms-input-placeholder {
    opacity: 1;
    color: #c2c2c2;
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 1.2px
}

.search__input::-ms-input-placeholder {
    opacity: 1;
    color: #c2c2c2;
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 1.2px
}

.search__input::placeholder {
    opacity: 1;
    color: #c2c2c2;
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 1.2px
}

@media (max-width: 768px) {
    .search__input::-webkit-input-placeholder {
        font-size: 15px;
        font-size: 1.5rem
    }
    .search__input::-moz-placeholder {
        font-size: 15px;
        font-size: 1.5rem
    }
    .search__input:-ms-input-placeholder {
        font-size: 15px;
        font-size: 1.5rem
    }
    .search__input::-ms-input-placeholder {
        font-size: 15px;
        font-size: 1.5rem
    }
    .search__input::placeholder {
        font-size: 15px;
        font-size: 1.5rem
    }
}

.search__icon {
    position: absolute;
    background: url(img/search.svg);
    width: 26px;
    height: 22px;
    top: calc(50% - 11px);
    left: 20px;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    -webkit-transition: .5s ease all;
    transition: .5s ease all;
    background-size: contain;
    background-repeat: no-repeat;
}

.game-filter {
    padding: 30px 20px 0;

    @include mobile {
        padding: 15px 10px 0;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet {
            flex-direction: column;
            align-items: center;
        }
    }

    &__controls {
        display: flex;
        align-items: center;

        @include tablet {
            flex-direction: column;
            align-items: center;
        }

        & > * {
            @include tablet {
                margin: 1rem;
            }
        }

    }

    &__select {
        $color: #35e2c5;

        .jcf-select {
            height: 50px;
            background-color: transparent;
            border: 3px solid $color;
            border-radius: 5px;
            width: 220px;
            margin: 0;

            @include mobile {
                height: 46px;
                line-height: 40px;
            }

            &.jcf-drop-flipped {

                &.jcf-drop-active {
                    border-bottom: 3px solid $color !important;
                    border-top-color: transparent !important;
                    border-radius: 0 0 5px 5px !important;
                }

            }


            &.jcf-focus {
                border-color: $color !important;
            }

            &.jcf-drop-active {
                border-color: $color !important;
                border-radius: 5px 5px 0 0 !important;
                border-bottom-color: transparent !important;
                background-color: #242f38;
            }

            .jcf-select-text {
                line-height: 46px;
                color: $color;
                font-size: 1.4rem;

                @include mobile {
                    line-height: 40px;
                }
            }

            .jcf-select-opener {
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;

                &:after {
                    content: "\f107";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 3rem;
                    right: 0.6rem;
                    color: $color;
                }
            }
        }

        .jcf-select-opener {
            background-color: transparent;
        }

        .jcf-select-drop {
            left: -3px;

            .jcf-select-drop-content {
                border: 3px solid $color !important;
                border-top-color: transparent !important;
                border-radius: 0 0 5px 5px !important;
            }

            &.jcf-drop-flipped {

                .jcf-select-drop-content {
                    border-top: 3px solid $color !important;
                    border-bottom-color: transparent !important;
                    border-radius: 5px 5px 0 0 !important;
                }

            }

            .jcf-list {
                background-color: rgba(#242f38, .8);
            }

            .jcf-option {
                color: $color;
                height: 30px;
                line-height: 16px;
            }

            .jcf-hover {
                color: white;
                cursor: pointer;
                background: #35e2c57a;
            }
        }

        .jcf-scrollbar {
            background-color: transparent;
        }

        .jcf-scrollbar-vertical .jcf-scrollbar-dec, .jcf-scrollbar-vertical .jcf-scrollbar-inc {
            background-color: transparent;
        }

        .jcf-scrollbar-handle {
            background: #35e2c5;
            border-radius: 5px;
            width: 8px;
        }

    }
}

.game-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    @include mobile {
        max-width: 95vw;
        overflow: auto;
    }

    .menu {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        li {
            cursor: pointer;
            -webkit-transition: .35s;
            transition: .35s;
            margin-right: 10rem;

            @media (max-width: 1450px) {
                margin-right: 2rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                text-transform: uppercase;
                margin-top: 14px;
                display: inline;
                white-space: nowrap;
                color: #fff;
                font-weight: 700;
                font-size: 14px;
                text-decoration: none;

                img {
                    max-width: 30px;
                    margin-right: 1rem;
                    position: relative;
                }

                &:hover {
                    color: yellow;
                }
            }
        }
    }

    @include tablet {
        margin-bottom: 2rem;

        &:last-child {
            margin-right: 0;
        }
    }

    @include mobile {
        border-bottom: 3px solid;
    }
}

.game-type__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    cursor: pointer;
    -webkit-transition: .35s;
    transition: .35s;
    margin-right: 10rem;

    @include mobile {
        padding-bottom: 1.5rem;
    }

    &.active {
        @include mobile {
            position: relative;

            .game-type__title {
                color: yellow;
            }

            &:before {
                content: "";
                position: absolute;
                height: 5px;
                width: 100%;
                left: 0;
                bottom: -4px;
                background-color: yellow;
                box-shadow: 0 0 4px rgba(yellow, 0.5);
            }
        }
    }

    @include tablet {
        margin-right: 2rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.game-type__item:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.game-type__item:after {
    content: '';
    display: block;
    margin-top: 8px;
    border-bottom: 4px solid #c2c2c2
}

.game-type__item--active .game-type__title {
    color: #fdf151;
    position: relative
}

.game-type__item--active .game-type__title:after {
    content: '';
    display: block;
    background: #fdf151;
    -webkit-box-shadow: -.3px -.3px 3px #fdf151;
    box-shadow: -.3px -.3px 3px #fdf151;
    height: 6px;
    border-radius: 4px;
    position: absolute;
    width: 100%;
    bottom: -13px;
    left: 0
}

@media (min-width: 992px) {
    .game-type__item--active .game-type__title:after {
        display: none
    }
}

.game-type__item--active:hover {
    -webkit-transform: none;
    transform: none
}

.game-type__item--active .game-type__image--all {
    background: url(img/type-game/icon-all_game-active.svg) no-repeat center center;
    background-size: 48px
}

@media (min-width: 700px) and (max-width: 1370px) {
    .game-type__item--active .game-type__image--all {
        background-size: 32px
    }
}

.game-type__item--active .game-type__image--poker {
    background: url(img/type-game/icon-poker-active.svg) no-repeat center center;
    background-size: 48px
}

@media (min-width: 700px) and (max-width: 1370px) {
    .game-type__item--active .game-type__image--poker {
        background-size: 30px
    }
}

.game-type__item--active .game-type__image--slots {
    background: url(img/type-game/icon-slots-active.svg) no-repeat center center;
    background-size: 48px
}

@media (min-width: 700px) and (max-width: 1370px) {
    .game-type__item--active .game-type__image--slots {
        background-size: 32px
    }
}

.game-type__item--active .game-type__image--jackpot {
    background: url(img/type-game/icon-jackpot-active.svg) no-repeat center center;
    background-size: 48px
}

@media (min-width: 700px) and (max-width: 1370px) {
    .game-type__item--active .game-type__image--jackpot {
        background-size: 32px
    }
}

.game-type__item--active .game-type__image--table {
    background: url(img/type-game/icon-table-active.svg) no-repeat center center;
    background-size: 48px
}

@media (min-width: 700px) and (max-width: 1370px) {
    .game-type__item--active .game-type__image--table {
        background-size: 32px
    }
}

.game-type__item--active .game-type__image--lottery {
    background: url(img/type-game/icon-lottery-active.svg) no-repeat center center;
    background-size: 48px
}

@media (min-width: 700px) and (max-width: 1370px) {
    .game-type__item--active .game-type__image--lottery {
        background-size: 32px
    }
}

.game-type__image--all {
    background: url(img/type-game/icon-all_game.svg) no-repeat center center
}

.game-type__image--poker {
    background: url(img/type-game/icon-poker.svg) no-repeat center center
}

.game-type__image--slots {
    background: url(img/type-game/icon-slots.svg) no-repeat center center
}

.game-type__image--jackpot {
    background: url(img/type-game/icon-jackpot.svg) no-repeat center center
}

.game-type__image--table {
    background: url(img/type-game/icon-table.svg) no-repeat center center
}

.game-type__image--lottery {
    background: url(img/type-game/icon-lottery.svg) no-repeat center center
}

.game-type__image {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    background-size: 32px;
    position: relative;

    @include tablet {
        display: none;
    }
}


.game-type__title {
    text-transform: uppercase;
    margin-top: 14px;
    display: inline;
    white-space: nowrap;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    font-size: 1.4rem
}

.game-list {
    margin: 2rem 20px;
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(7, 1fr);

    @include tablet {
        grid-template-columns: repeat(4, 1fr);
    }

    @include mobile {
        grid-template-columns: repeat(2, 1fr);
    }

    &__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 132px;
        background-color: #000;
        overflow: hidden;
        cursor: pointer;

        &._add {
            color: white;
            font-size: 3rem;
            background-color: #4c5e6b;
            text-decoration: none;
            flex-direction: column;
            align-items: center;

            .game-list__item-name {
                margin-top: 1rem;
                font-size: 1.25rem;
            }

            &:after {
                display: none;
            }
        }

        &:after {
            content: "";
            background-image: url(img/icon-play-game.svg);
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: all .2s;
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }

        &:hover & {

            &-img {
                opacity: 0.4;
                transition: all .2s;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;

        }
    }
}

.description-text {
    width:90%;
    margin:30px auto 0 auto;
    font-size:15px;

    h4 {
        text-align: center;
    }

}
.description-title {
    padding:15px 0;
    font-size:18px;
    letter-spacing:.1em;
    font-weight:700;
    margin-top:60px;
    text-align: center;
}
.description-list {
    font-size:14px;
}

.logged-in.admin-bar .content .header {
    top: 32px;
}
@media screen and (max-width: 782px) {
    .logged-in.admin-bar .content .header {
        top: 0;
        z-index: 99999999;
    }
}

.error-404 {
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    padding:20px;
    height:65vh;

    @include mobile {
        height: auto;
    }
}
.error-404__images {
    background:url(img/logo-404.png) no-repeat center center;
    background-size:contain;
    width:300px;
    height:325px
}
@media (min-width:200px) and (max-width:700px) {
    .error-404__images {
        width:100px;
        height:125px
    }
}
@media (min-width:700px) and (max-width:820px) {
    .error-404__images {
        width:180px;
        height:200px
    }
}
.error-404__link {
    color:#fdf151;
    text-decoration:underline
}
.error-404__text {
    margin:0 0 0 5rem;
}
.error-text__item {
    text-align:left;
    text-transform:none;
    font-size:30px;
}
@media only screen and (max-width:768px) {
    .error-text__item {
        font-size:20px;
    }
}
@media only screen and (max-width:660px) {
    .error-text__item {
        font-size:16px;
    }
}



.page-news__title-box,
.page-game__title-box{
    margin:15px auto;
    text-align:center;
    width:610px;

    @include mobile {
        width: 100%;
    }
}
.page-news-item {
    padding:0
}
.news-wrap {
    margin:0 auto;
    padding: 0;
    max-width:1224px;
    min-width:320px;

    @include mobile {
        min-width: 0;
    }
}
.news-box {
    margin:0 0 60px
}
.news-banner {
    padding:90px 0;
    height:360px;
    background:url(img/bg/bg-promo-item.jpg) top center no-repeat;
    background-size:cover
}
.news-banner__title-box {
    position:relative;
    margin:110px auto;
    max-width:1024px
}
.news-banner__title-box::after,
.news-banner__title-box::before {
    content:'';
    position:absolute;
    top:0;
    width:46%;
    height:1px;
    background:#fff;
    -webkit-box-shadow:0 0 7px 0 #000;
    box-shadow:0 0 7px 0 #000
}
.news-banner__title-box::before {
    left:0
}
.news-banner__title-box::after {
    right:0
}
.news-text {
    padding:20px 20px 60px 20px
}
.news-list {
    margin:40px 0
}
.news-list__item {
    display:inline-block;
    float:left;
    margin:0;
    padding:3% 4%;
    width:25%;
    height:320px
}
.news-item {
    display:block;
    position:relative;
    text-align:center
}
.news-item__type {
    position:absolute;
    top:-20px;
    right:-20px;
    width:66px;
    height:66px;
    -webkit-box-shadow:0 0 14px 0 #000;
    box-shadow:0 0 14px 0 #000;
    font-family:"Roboto Slab Bold";
    text-transform:uppercase;
    text-align:center;
    line-height:66px;
    letter-spacing:1px;
    font-size:11px;
    font-size:1.1rem;
    z-index:10
}
.news-item__type_violet {
    background:#9e7ac2;
    color:#fff
}
.news-item__type_yellow {
    background:#fdf151;
    color:#9e7ac2
}
.news-item__link {
    display:block
}
.news-item__image {
    display:block;
    margin:0 auto;
    padding:10px;
    max-width:222px;
    width:100%;
    max-height:222px;
    min-height:222px;
    height:auto;
    border:1px solid #0afbda;
    -webkit-box-shadow:inset 0 0 7px 0 #0afbda,0 0 7px 0 #0afbda;
    box-shadow:inset 0 0 7px 0 #0afbda,0 0 7px 0 #0afbda
}
.news-item__image:hover {
    opacity:.5
}
.news-item__title {
    display:block;
    margin:7px 0 0;
    padding:0 30px;
    font-family:"Roboto Slab Bold";
    text-transform:uppercase;
    text-align:center;
    letter-spacing:1px;
    font-size:12px;
    font-size:1.2rem
}
@media only screen and (max-width:1080px) {
    .news-item__image {
        min-height:200px
    }
}
@media only screen and (max-width:960px) {
    .news-wrap {
        width:98%
    }
    .news-item__image {
        min-height:222px
    }
}
@media only screen and (max-width:860px) {
    .news-item__image {
        min-height:140px
    }
}
@media only screen and (max-width:768px) {
    .news-wrap .title-decor_red {
        border:none;
        padding:5px 0;
        font-size:30px
    }
    .news-wrap .title-decor_red::after {
        content:none
    }
    .page-news {
        padding-top:0;

        &._single {
            @include tablet {
                padding: 0 10px;
            }
        }
    }
    .page-news__title-box {
        width:100%
    }
    .news-list {
        margin:20px 0
    }
    .news-list__item {
        padding:2% 3%;
        height:280px
    }
    .news-item__title {
        padding:0
    }
    .news-item__image {
        min-height:120px
    }
}
@media only screen and (max-width:660px) {
    .news-item__image {
        min-height:170px
    }
}
@media only screen and (max-width:620px) {

    .news-list__item {
        width:50%
    }
    .news-item__image {
        min-height:200px
    }
}
@media only screen and (max-width:480px) {
    .news-list__item {
        padding:3% 4%;
        height:250px
    }
    .news-item__image {
        min-height:160px;
        min-width:160px
    }
}
@media only screen and (max-width:390px) {
    .news-list__item {
        height:214px
    }
    .news-item__type_violet,
    .news-item__type_yellow {
        width:42px;
        height:42px;
        line-height:42px;
        font-size:6px;
        font-size:.6rem
    }
    .news-item__image {
        min-height:135px;
        min-width:135px
    }
}


.title-decor {
    display:inline-block
}
.title-decor_red {
    position: relative;
    padding:5px 30px;
    border:2px solid #e44f5b;
    border-radius:6px;
    letter-spacing:6px;
    color:#27323b;
    font-family:MarvinVision;
    text-transform:uppercase;
    text-shadow:-1px -1px 0 #e44f5b,1px -1px 0 #e44f5b,-1px 1px 0 #e44f5b,1px 1px 0 #e44f5b;
    font-size:55px;
    font-size:5.5rem
}
.title-decor_red::after,
.title-decor_red::before {
    content:'';
    position:absolute;
    left:0;
    right:0;
    margin:0 auto
}
.title-decor_red::before {
    top:-2px;
    width:50px;
    height:3px;
    background-color:#27323b
}
.title-decor_red::after {
    content:url(img/navbar-icon_active.svg);
    top:-50px;
}
.title-decor_white {
    padding:28px 0;
    text-shadow:0 0 7px #000;
    font-size:40px;
    font-size:4rem
}
.title-decor_white::after,
.title-decor_white::before {
    content:'';
    position:absolute;
    left:0;
    right:0;
    margin:0 auto
}
.title-decor_white::before {
    content:'';
    background:url(img/icons/icon-logo-white.png) no-repeat center center;
    background-size:contain;
    width:35px;
    height:35px;
    top:-34px
}
.title-decor_white::after {
    bottom:0;
    width:100%;
    height:1px;
    background:#fff;
    -webkit-box-shadow:0 0 7px 0 #000;
    box-shadow:0 0 7px 0 #000
}
.text-header {
    margin-top:20px;
    font-size:40px;
    font-size:4rem
}
.text-description {
    font-size:22px;
    font-size:2.2rem
}


.promo-list {
    margin:40px 0;
    display: grid;
    grid-template-columns: repeat(4, 220px);
    grid-gap: 10rem;

    @include tablet {
        padding: 30px;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2.5rem;
    }

    @include mobile {
        padding: 1.5rem;
        grid-template-columns: repeat(2, 1fr);
        margin: 0;
        grid-gap: 1.5rem;
    }
}
.promo-list__item {
    position: relative;
    flex:0 0 170px;
    color: white;

    &-type {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top:-20px;
        right:-20px;
        width:66px;
        height:66px;
        font-size: 1.5rem;
        z-index:10;
        box-shadow:0 0 14px 0 #000;

        @include mobile {
            top:-10px;
            right:-10px;
            width:46px;
            height:46px;
            font-size: 1rem;
        }

        &._yellow {
            background-color: #fdf151;
            color: #9e7ac2;
        }

        &._violet {
            background:#9e7ac2;
            color:#fff
        }
    }
}
@media (min-width:567px) {
    .promo-list__item {
        flex:0 0 270px
    }
}
.promo-item__link {
    display:block;
    color: white;
    text-decoration: none;
}
.promo-item__image {
    display:block;
    margin:0 auto;
    padding:10px;
    max-width:100%;
    height:auto;
    width:100%;
    object-fit: cover;
    border:1px solid #0afbda;
    -webkit-box-shadow:inset 0 0 7px 0 #0afbda,0 0 7px 0 #0afbda;
    box-shadow:inset 0 0 7px 0 #0afbda,0 0 7px 0 #0afbda;
}
@media (min-width:567px) {
    .promo-item__image {
        height:222px;
    }
}
.promo-item__image:hover {
    opacity:.5
}
.promo-item__title {
    display:block;
    margin:7px 0 0;
    padding:0 30px;
    font-weight: 700;
    text-transform:uppercase;
    text-align:center;
    letter-spacing:1px;
    font-size:12px;
    font-size:1.2rem;

    @include mobile {
        padding: 0 10px;
    }
}

.banner {
    .swiper-slide {
        img {
            max-height: 62vh;
            width: 100%;
            object-fit: cover;
        }
    }

}

.game-page {
    &__container {
        padding-top: 40px;
        background-size: cover;
        min-height: 440px;
        display: flex;
        justify-content: center;

        @include mobile {
            padding-top: 0;
            min-height: auto;
        }
    }

    &__main {
        display: flex;
        justify-content: center;
        flex-grow: 1;

        &-right {
            width: 250px;
            display: flex;
        }

        &-left {

            iframe {
                width: 70vw;
                height: 32vw;

                @include mobile{
                    width: 95vw;
                    height: 50vw;
                }
            }
        }
    }

    &__bottom {

        &-container {
            max-width: 980px;
            margin: 0 auto;
            padding: 20px 0;
        }
    }

    &__play {
        text-align: center;
        margin: 0 0 50px 0;
        padding: 0 0 25px 0;
        border-bottom: 1px solid white;
    }

    &__info {
        display: flex;

        @include tablet {
            flex-direction: column;
            align-items: center;
        }
    }

    &__characteristics {
        width: 280px;
        flex-shrink: 0;
    }

    &__demo {
        padding-left: 20px;
        flex-grow: 1;

        @include mobile {
            padding: 0 20px;
        }

        img {
            width: 100%;
            height: auto;

            @include tablet {
                margin-top: 1.5rem;
            }
        }
    }
}



.feature-box {
    position: relative;
    padding: 50px 50px 20px 60px;
    border: 1px solid white;
    border-radius: 15px;
    box-sizing: border-box;
    color: white;
}

.feature-box .feature-box-title {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    font-family: "Noto Sans",Arial,Helvetica,sans-serif;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
}

.feature-box .feature-box-title span {
    padding: 0 15px;
    background: #d45a5f;
}

.feature-box ul li {
    position: relative;
    padding: 0 0 15px 0
}

.feature-box ul li::before {
    position: absolute;
    top: 5px;
    left: -20px;
    width: 4px;
    height: 4px;
    content: "";
    background: #f9b242;
    border-radius: 4px
}

.feature-box ul li span {
    color: #f9b242
}

.new-width {
    width: 100%;
    max-width: 70vw;
    min-width: 320px;
    margin: 0 auto;
    padding: 0 15px;
    font-size: 1.5rem;
    box-sizing: border-box;
    text-align: center;

    @include mobile {
        max-width: 95%;
    }
}

.breadcrumbs-box {
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding: 0 0 5px 0;
    color: #797979;
    border-bottom: 1px solid rgba(121,121,121,.3);
}

.breadcrumbs-list {
    display: flex;
    font-size: 1.4rem;

    @include mobile {
        align-items: flex-end;
    }
}

.breadcrumbs-list__item {
    margin: 0 15px 0 0
}

.breadcrumbs-list__item::after {
    padding: 0 0 0 15px;
    vertical-align: middle;
    content: ">"
}

.breadcrumbs-list__item:last-child::after {
    content: ""
}

.breadcrumbs-list__link {
    color: yellow;
}

.article-item {
    .title {
        padding: 0 0 20px 0;
        font-family: "Noto Sans",Arial,Helvetica,sans-serif;
        font-weight: 700;
        color: #ededed;

        @include mobile {
            padding: 0;
        }
    }

    .article-date-box {
        display: block;
        padding: 15px 0 10px 0;
        color: #797979;
    }

    .article-date-icon {
        margin: 0 10px 0 0;
        width: 16px;
        height: 16px;
        fill: #797979;
    }

    p {
        margin: 15px 0 30px 0;
        line-height: 1.5;
        font-size: 1.4rem;
    }

    .article-image {
        margin: 15px 0 0 0;
        width: 100%;
        height: auto;
    }

    .sub-title {
        padding: 0 0 20px 0;
        color: #d45a5f;
        font-size: 2rem;
    }
    
    a {
        color: #d45a5f;
        text-decoration: underline;
        
        &:hover {
            text-decoration: none;
        }
    }
}

.tournament {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:distribute;
    justify-content:space-around;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    background-color:#1b2c34;
    padding:45px 30px 30px;
    margin-bottom:35px;
    font-size:15px;
    font-size:1.5rem
}
@media only screen and (max-width:768px) {
    .tournament {
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -ms-flex-direction:column;
        flex-direction:column;
        -webkit-box-align:center;
        -ms-flex-align:center;
        align-items:center
    }
}
@media only screen and (max-width:620px) {
    .tournament {
        padding:20px
    }
}
.tournament-item {
    width:300px;
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    padding:0 20px
}
.tournament-grid {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    grid-gap: 7px;
}
.tournament-item.tournament-carousel {
    flex:0 1 auto;
    width:calc(100% / 3)
}
.tournament-item.tournament-carousel .game_item--picture {
    display: block;
    height: 100%;
}
.tournament-item.tournament-carousel .game-item {
    position: relative;
    width: 100%;
    height: 80px;
    object-fit: cover;
}
.tournament-item.tournament-carousel .game-item__play {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url(img/icon-play-game.svg) no-repeat center center;
    background-size: contain;
    width: 50px;
    height: 50px;
    -webkit-transition: .35s;
    transition: .35s;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    cursor: pointer;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
}
.tournament-item.tournament-carousel .game-item:hover:after{
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);
    position: absolute;
    left: 0;
    top: 0;
}

.tournament-item.tournament-carousel .game-item:hover .game-item__play {
    opacity: 1;
    visibility: visible;
}
@media only screen and (max-width:768px) {
    .tournament-item.tournament-carousel {
        width:calc(100% - 50px)
    }
}
.tournament-item .slick-list .game-item {
    min-height:78px
}
@media only screen and (max-width:768px) {
    .tournament-item {
        width:100%
    }
}
.tournament-timer-info h3 {
    font-family:Roboto Slab;
    font-style:normal;
    font-weight:700;
    font-size:14px;
    line-height:18px;
    text-align:center
}
.tournament-info__participate-text {
    display:inline-block;
    margin:15px auto;
    padding:12px 30px;
    color:#fff;
    border-radius:10px;
    text-transform:uppercase;
    font-family:"Roboto Slab Bold";
    text-align:center
}
@media only screen and (max-width:768px) {
    .tournament-item+.tournament-item {
        margin-bottom:1rem
    }
}
.tournament-info {
    text-align:center;
    padding:0 35px
}
@media only screen and (max-width:768px) {
    .tournament-info {
        flex-basis:auto;
        margin-bottom:0
    }
}
.tournament-info__title-type {
    text-transform:uppercase
}
.tournament-info__title-name {
    display:block;
    margin-top:15px;
    color:#fdf151;
    text-transform:uppercase;
    text-decoration:underline;
    font-family:Tahoma;
    margin-bottom:16px;
    font-size:3.6rem;
    line-height:3.5rem
}
@media only screen and (max-width:620px) {
    .tournament-info__title-name {
        font-size:3.2rem
    }
}
.tournament-info__title-name:hover {
    text-decoration:underline
}
.tournament-info__icon {
    width:30px;
    margin:20px 0 10px
}
.tournament-info__date {
    line-height:2.5rem
}
.tournament-info__title-table {
    display:block;
    margin:35px 0 25px;
    color:#0afbda;
    text-decoration:underline
}
.tournament-carousel {
    padding:0 20px
}
.tournament-carousel .game-item:hover {
    -webkit-transform:none;
    transform:none
}
@media only screen and (max-width:768px) {
    .tournament-carousel {
        width:100%
    }
}
@media only screen and (max-width:620px) {
    .tournament-carousel {
        padding:0
    }
    .tournament-carousel .game-item {
        width:100%;
        height:auto
    }
    .tournament-carousel .game_item--picture {
        max-height:none
    }
    .tournament-carousel .slick-prev {
        left:-25px
    }
    .tournament-carousel .slick-next {
        right:-25px
    }
}
.tournament-carousel .slick-next,
.tournament-carousel .slick-prev {
    position:absolute;
    top:50%;
    width:25px;
    height:30px;
    cursor:pointer;
    border:none;
    outline:0
}
.tournament-carousel .slick-prev {
    background:url(img/arrow/arrow-left.svg) no-repeat center center
}
.tournament-carousel .slick-prev:hover {
    background:url(img/arrow/arrow-left__active.svg) no-repeat center center
}
.tournament-carousel .slick-next {
    background:url(img/arrow/arrow-left.svg) no-repeat center center;
    -webkit-transform:rotate(180deg) translate(0,50%);
    transform:rotate(180deg) translate(0,50%)
}
.tournament-carousel .slick-next:hover {
    background:url(img/arrow/arrow-left__active.svg) no-repeat center center
}
.tournament-carousel .slick-slide>div {
    margin:6px
}
.tournament-carousel .slick-dots li button[type=button] {
    background:0 0;
    display:block;
    height:10px;
    width:10px;
    cursor:pointer;
    background:#0afbda;
    border-radius:50%
}
.tournament-carousel .slick-dots li button[type=button]:before {
    content:''
}
.tournament-carousel .slick-active button[type=button] {
    border:3px solid #e44f5b;
    border-radius:50%
}
.tournament-prize {
    text-align:center;
    width:300px
}
.tournament-prize__logo {
    width:130px;
    height:130px;
    margin:0 auto
}
.tournament-prize__title {
    padding-top:10px;
    text-transform:uppercase;
    font-size:2rem
}
.tournament-prize__sum {
    color:#fdf151;
    margin:10px 0;
    font-size:4.6rem
}
.tournament-prize__prize-place {
    text-transform:uppercase;
    font-size:1.4rem
}
.tournament-prize__prize-place .prize-place {
    font-family:"Roboto Slab Bold"
}
.timer-block {
    position:relative;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:340px;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    margin:0 auto 25px;
    padding:12px 40px
}
.timer-block__title {
    -webkit-transform:translateX(-50%);
    transform:translateX(-50%);
    width:45px;
    height:18px;
    font-size:10px;
    font-size:1rem
}
.timer-block__top-title {
    top:-10px;
    width:150px
}
.timer-block__date-title {
    bottom:-24px;
    font-size:.8rem;
    line-height:10px
}
.timer-block__item {
    position:relative;
    height:48px;
    width:48px;
    border-radius:50%;
    background:rgba(192,192,192,.3);
    text-transform:uppercase;
    padding-top:12px
}
.timer-block__item .timer-block__value {
    display:block;
    line-height:12px
}
.tournament-table {
    margin:35px 0
}
.tournament-table table {
    font-family:Roboto Slab
}
.tournament-table table thead span {
    color:#fff;
    font-style:normal;
    font-weight:700;
    font-size:1.6rem;
    line-height:2.1rem
}
.tournament-table table tbody tr td span {
    font-style:normal;
    font-weight:400;
    font-size:1.4rem;
    line-height:1.8rem
}
.tournament-table table tbody tr td:not(:last-child) {
    border-right:1px solid rgba(255,255,255,.2)
}
.tournament-detail {
    font-size:1.5rem;
    line-height:2.2rem
}
@media only screen and (max-width:620px) {
    .big-image__center {
        padding:20px 0
    }
}
.tournament-detail__big-image {
    position:relative;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:distribute;
    justify-content:space-around;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    background:url(img/tournaments/bg-tournaments.jpg) no-repeat center center;
    background-size:cover;
    width:100%;
    height:515px;
    overflow:hidden
}
@media only screen and (max-width:620px) {
    .tournament-detail__big-image {
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        height:550px
    }
}
.tournament-detail__big-image .rays-left-box,
.tournament-detail__big-image .rays-right-box {
    position:absolute;
    bottom:0
}
.tournament-detail__big-image .big-left-ray,
.tournament-detail__big-image .big-right-ray {
    position:absolute;
    bottom:0;
    width:300px;
    height:600px
}
@media only screen and (max-width:1200px) {
    .tournament-detail__big-image .big-left-ray,
    .tournament-detail__big-image .big-right-ray {
        display:none
    }
}
.tournament-detail__big-image .big-left-ray {
    background:url(img/tournaments/ray-big-left.png) no-repeat center center;
    background-size:contain
}
.tournament-detail__big-image .big-right-ray {
    background:url(img/tournaments/ray-big-right.png) no-repeat center center;
    background-size:contain
}
.tournament-detail__big-image .tournament-info__title-name:hover {
    text-decoration:none;
    cursor:unset
}



.nav-content {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    position:relative;
    padding:55px 0 20px;
    margin:0 auto
}
.nav-content .jcf-scrollbar-horizontal {
    bottom:-10px;
    height:3px;
    background:#435c70;
    border:none
}
.nav-content .jcf-scrollbar-horizontal .jcf-scrollbar-dec,
.nav-content .jcf-scrollbar-horizontal .jcf-scrollbar-inc {
    width:3px;
    height:3px;
    background:#435c70
}
.nav-content .jcf-scrollbar-horizontal .jcf-scrollbar-handle {
    top:-7px;
    background:#0afbda;
    border-radius:10px
}
.nav-content__title {
    margin:0 0 0 30px;
    padding:0;
    color:#27323b;
    letter-spacing:6px;
    text-transform:uppercase;
    text-shadow:-1px -1px 0 #e44f5b,1px -1px 0 #e44f5b,-1px 1px 0 #e44f5b,1px 1px 0 #e44f5b;
    font-size:4rem
}
.navbar-mob {
    display:none
}
.nav-tabs {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:distribute;
    justify-content:space-around;
    position:relative;
    width:80%;
    margin:0 auto;
    border:2px solid #e44f5b;
    border-radius:10px;
    cursor: pointer;
}
.nav-tabs__item {
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    text-align:center
}
.nav-tabs__link {
    display:block;
    margin:0;
    padding:0;
    text-transform:uppercase;
    text-decoration:none;
    font-size:1.5rem;
    line-height:5.2rem;
    color: white;
}
.nav-tabs__link_active {
    position:relative;
    color:#e44f5b;
    text-shadow:0 0 40px #e44f5b
}
.nav-tabs__link_active::after,
.nav-tabs__link_active::before {
    content:"";
    position:absolute;
    left:0;
    right:0;
    margin:0 auto
}
.nav-tabs__link_active::before {
    top:-2px;
    width:50px;
    height:3px;
    background-color:#27323b
}
.nav-tabs__link_active::after {
    content:url(img/navbar-icon_active.svg);
    top:-37px;
    width:30px
}
.nav-tabs-cabinet-mob {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
@media only screen and (max-width:1366px) {
    .nav-tabs__link {
        font-size:1.1rem
    }
}
@media only screen and (max-width:1024px) {
    .nav-tabs__link {
        line-height:40px
    }
    .nav-content__title {
        display:none
    }
    .nav-tabs__link {
        font-size:1.2rem
    }
}
@media only screen and (max-width:960px) {
    .navbar-desktop {
        display:none
    }
    .navbar-mob {
        display:block
    }
    .navbar-mob .nav-tabs {
        border:none;
        border-radius:0;
        margin:10px auto;
        width:100%;
        border-top:2px solid #e44f5b;
        border-bottom:2px solid #e44f5b
    }

}
@media only screen and (max-width:768px) {
    .nav-content {
        width:95%
    }
    .nav-tabs__link {
        font-size:1.1rem
    }
    .tournament-hight-block .navbar-mob.nav-content {
        padding: 25px 0 20px;
    }
}
@media only screen and (max-width:480px) {
    .nav-content__title {
        display:none
    }
    .nav-content .slick-list {
        height:40px
    }
}

.burger {
    position: fixed;
    right: 2rem;
    bottom: 3rem;
    width: 60px;
    height: 60px;
    z-index: 99999999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0afbda;
    border-radius: 50%;
    box-shadow: 0 5px 8px rgba(0,0,0,.3);
    display: none;

    @include tablet {
        display: flex;
    }

    &.active {
        span {
            &:nth-child(1) {
                transform: rotate(-45deg) translate(-7px, 7px);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                transform: rotate(45deg) translate(-4px,-4px);
            }
        }
    }

    span {
        display: inline-block;
        width: 30px;
        height: 4px;
        border-radius: 2px;
        background-color: white;
        transition: all .2s;

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
}

.new-tournaments{
    display: none;

    &.active{
        display: block;
    }
}

.no-results{
    margin: 0 auto;
    padding: 0;
    max-width: 1224px;
    min-width: 320px;
    font-size: 12px;
}

.no-results form.search {
    margin: 20px 0;
}

.hidden{
    display: none !important;
}