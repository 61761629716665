@import "media";

#footer-big {
  display:block;
  margin-top: auto;
}
.provider-list {
  margin:0;
  padding:36px 0 0 0;
  text-align:center
}
.provider-list__item {
  display:inline-block;
  padding:0 20px 40px 20px;
  vertical-align:middle;
  -webkit-transition:.35s;
  transition:.35s
}
.provider-list__item:hover {
  -webkit-transform:scale(1.05);
  transform:scale(1.05)
}
.certificate-list {
  text-align:center
}
.certificate-list__item {
  display:inline-block;
  padding:0 15px;
  vertical-align:middle;
  -webkit-transition:.35s;
  transition:.35s
}
.certificate-list__item:hover {
  -webkit-transform:scale(1.05);
  transform:scale(1.05)
}
.certificate-list__link {
  display:block
}
.icon-betsoft,
.icon-bigtimegaming,
.icon-blueprint,
.icon-booongo,
.icon-curacao,
.icon-elk,
.icon-endorphina,
.icon-evolutiongaming,
.icon-habanero,
.icon-microgaming,
.icon-nextgen,
.icon-nolimitcity,
.icon-pagi,
.icon-quickfire,
.icon-quickspin,
.icon-redtiger,
.icon-relax,
.icon-security,
.icon-spinomenal,
.icon-thunderkick {
  display:block
}
.icon-quickfire {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1156px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:92px
}

.icon-netent {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -900px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:91px
}

.icon-yggdrasil {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1533px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:132px
}

.icon-quickspin {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1190px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:24px;
  width:100px
}

.icon-curacao {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -268px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:58px;
  width:50px
}

.icon-itechlabs {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -746px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:53px;
  width:53px
}

.icon-pagi {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1002px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:53px;
  width:53px
}

.icon-comodo {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -219px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:47px;
  width:119px
}

.icon-askgamblers {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -34px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:47px;
  width:142px
}

.icon-pushgaming {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1091px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:15px;
  width:140px
}

.icon-nextgen {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -934px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:116px
}

.icon-elk {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -328px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:81px
}

.icon-thunderkick {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1358px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:103px
}

.icon-playngo {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1057px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:116px
}

.icon-amatic {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 0;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:104px
}

.icon-bigtimegaming {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -117px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:127px
}

.icon-betsoft {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -83px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:84px
}

.icon-endorphina {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -362px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:48px;
  width:105px
}

.icon-relax {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1243px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:103px
}

.icon-nolimitcity {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -968px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:119px
}

.icon-blueprint {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -151px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:137px
}

.icon-habanero {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -470px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:28px;
  width:136px
}

.icon-booongo {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -185px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:32px;
  width:132px
}

.icon-evolutiongaming {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -412px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:18px;
  width:140px
}

.icon-redtiger {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1216px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:25px;
  width:132px
}

.icon-spinomenal {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1305px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:29px;
  width:161px
}

.icon-microgaming {
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -843px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:34px;
  width:138px
}

.askgamblers-link {
  font-size:8px;
  font-size:.8rem
}
.footer-information {
  margin-top:50px;
  color:#c9c9c9;
  font-family:"Roboto Slab";
  font-size:15px;
  font-size:1.5rem;

  @include tablet {
    padding: 1rem 20px;
  }
}
.important-know {
  background:#39434c;
  margin:40px 0
}
.important-know__links {
  width:80%;
  margin:auto;
  padding:14px 0;
  display:flex;
  justify-content:space-between;

  & > div {
    width: 100%;
  }

  @include tablet {
    flex-direction: column;
    align-items: center;

    a {
      margin: 1rem;
      text-align: center;
    }
  }
}
.important-know__links.centered {
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
.important-know__links.centered a {
  margin-right:46px
}
.important-know__links ul {
  list-style: none;
  display:flex;
  justify-content:space-between;
  padding: 0;
  margin: 0;
  
  @include mobile {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @include tablet {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.important-know__links a {
  color:#c9c9c9;
  font-weight:700;
  font-size:16px;
  line-height:30px;
  text-transform:uppercase;
  text-decoration: none;

  &:hover {
    color: yellow;
  }
}
.site-information {
  -ms-flex-preferred-size:650px;
  flex-basis:650px;
  text-align:center;
  line-height:1.8
}
@media (min-width:1024px) {
  .site-information {
    max-width:1200px;
    margin:auto
  }
  .site-information p {
    margin-top:5px
  }
}

.nav-footer-mob {
  position:fixed;
  bottom:-1px;
  left:0;
  right:0;
  margin:0 auto;
  min-width:300px;
  background:#39434c;
  padding:8px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:space-evenly;
  -ms-flex-pack:space-evenly;
  justify-content:space-evenly;
  z-index:120
}
.nav-content {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  position:relative;
  padding:55px 0 20px;
  margin:0 auto
}
.nav-content .jcf-scrollbar-horizontal {
  bottom:-10px;
  height:3px;
  background:#435c70;
  border:none
}
.nav-content .jcf-scrollbar-horizontal .jcf-scrollbar-dec,
.nav-content .jcf-scrollbar-horizontal .jcf-scrollbar-inc {
  width:3px;
  height:3px;
  background:#435c70
}
.nav-content .jcf-scrollbar-horizontal .jcf-scrollbar-handle {
  top:-7px;
  background:#0afbda;
  border-radius:10px
}
.nav-content__title {
  margin:0 0 0 30px;
  padding:0;
  color:#27323b;
  letter-spacing:6px;
  font-family:MarvinVision;
  text-transform:uppercase;
  text-shadow:-1px -1px 0 #e44f5b,1px -1px 0 #e44f5b,-1px 1px 0 #e44f5b,1px 1px 0 #e44f5b;
  font-size:40px;
  font-size:4rem
}
.navbar-mob {
  display:none
}
.nav-tabs {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:distribute;
  justify-content:space-around;
  position:relative;
  width:80%;
  margin:0 auto;
  border:2px solid #e44f5b;
  border-radius:10px
}
.nav-tabs__item {
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  text-align:center
}
.nav-tabs__link {
  display:block;
  margin:0;
  padding:0;
  font-family:"Roboto Slab Bold";
  text-transform:uppercase;
  text-decoration:none;
  font-size:15px;
  font-size:1.5rem;
  line-height:52px;
  line-height:5.2rem
}
.nav-tabs__link_active {
  position:relative;
  color:#e44f5b;
  text-shadow:0 0 40px #e44f5b
}
.nav-tabs__link_active::after,
.nav-tabs__link_active::before {
  content:"";
  position:absolute;
  left:0;
  right:0;
  margin:0 auto
}
.nav-tabs__link_active::before {
  top:-2px;
  width:50px;
  height:3px;
  background-color:#27323b
}
.nav-tabs__link_active::after {
  content:url(img/navbar/navbar-icon__active.svg);
  top:-37px;
  width:30px
}
.nav-tabs-cabinet-mob {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
@media only screen and (max-width:1366px) {
  .nav-tabs__link {
    font-size:11px;
    font-size:1.1rem
  }
}
@media only screen and (max-width:1024px) {
  .nav-tabs__link {
    line-height:40px
  }
  .nav-content__title {
    display:none
  }
  .nav-tabs__link {
    font-size:12px;
    font-size:1.2rem
  }
}
@media only screen and (max-width:960px) {
  .navbar-desktop {
    display:none
  }
  .navbar-mob {
    display:block
  }
  .navbar-mob .nav-tabs {
    border:none;
    border-radius:0;
    margin:10px auto;
    width:100%;
    border-top:2px solid #e44f5b;
    border-bottom:2px solid #e44f5b
  }
}
@media only screen and (max-width:768px) {
  .nav-content {
    width:95%
  }
  .nav-tabs__link {
    font-size:11px;
    font-size:1.1rem
  }
}
@media only screen and (max-width:480px) {
  .nav-content__title {
    display:none
  }
  .nav-content .slick-list {
    height:40px
  }
}

.payment {
  margin-top:80px
}
.payment .payment-box {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:distribute;
  justify-content:space-around;
  width:85%;
  margin: 0 auto;
  height:100%
}
.payment .payment-box .visa-icon::before {
  margin:-20px 0 0 -17px!important
}
.payment .payment-box .maestro-icon::before {
  margin:-20px 0 0 -18px!important
}
.payment .payment-box .qiwi-icon::before {
  margin:-20px 0 0 -13px!important
}
.payment .payment-box .webmoney-icon::before {
  margin:-20px 0 0 -13px!important
}
.payment .payment-box .yandex-wallet-icon::before {
  margin:-20px 0 0 -11px!important
}
.payment .payment-box .skrill-icon::before {
  margin:-20px 0 0 -19px!important
}
.payment .payment-box .neteller-icon::before {
  margin:-20px 0 0 -30px!important
}
.payment .payment-box__item {
  -ms-flex-preferred-size:100px;
  flex-basis:100px;
  height:90px;
  padding-top:25px;
  list-style:none
}
.payment .payment-box__link {
  position:relative;
  display:block;
  color:#fff;
  text-decoration:none;
  text-align:center;
  font-size:9px;
  font-size:.9rem
}
.payment .payment-box__link::before {
  content:"";
  position:absolute;
  top:0;
  left:50%
}
.payment .payment-box__text {
  display:block;
  padding:30px 0 0 0;
  width:100px;
  color:#fff;
  opacity:.4;
  -webkit-transition:all .1s ease-in-out;
  transition:all .1s ease-in-out;
  font-size:14px;
  font-size:1.4rem
}
.payment .payment-box .visa-icon::before {
  opacity:.4;
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1392px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:47px;
  width:49px;
  margin:-23.5px 0 0 -24.5px
}

.payment .payment-box .maestro-icon::before {
  opacity:.4;
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -801px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:40px;
  width:67px;
  margin:-20px 0 0 -33.5px
}

.payment .payment-box .qiwi-icon::before {
  opacity:.4;
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1108px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:46px;
  width:44px;
  margin:-23px 0 0 -22px
}

.payment .payment-box .webmoney-icon::before {
  opacity:.4;
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1441px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:45px;
  width:45px;
  margin:-22.5px 0 0 -22.5px
}

.payment .payment-box .yandex-wallet-icon::before {
  opacity:.4;
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1488px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:43px;
  width:38px;
  margin:-21.5px 0 0 -19px
}

.payment .payment-box .skrill-icon::before {
  opacity:.4;
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -1277px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:26px;
  width:76px;
  margin:-13px 0 0 -38px
}

.payment .payment-box .neteller-icon::before {
  opacity:.4;
  display:block;
  background-image:url(img/images-list-s58616c28e9.png);
  background-position:0 -879px;
  background-repeat:no-repeat;
  overflow:hidden;
  height:19px;
  width:107px;
  margin:-9.5px 0 0 -53.5px
}

.payment-item-box {
  margin:0 auto;
  -webkit-transition:.35s;
  transition:.35s
}
.payment-item-box:active {
  -webkit-transform:none;
  transform:none;
  text-shadow:0 0 1px #0afbda
}
.payment-item-box__title {
  display:block;
  margin:7px 0 0;
  padding:0;
  font-family:"Roboto Slab Bold";
  text-transform:uppercase;
  text-align:center;
  letter-spacing:1px;
  font-size:9px;
  font-size:.9rem
}
.payment-item {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  width:155px;
  height:110px;
  background:#3d4953;
  border-radius:5px
}
.payment-item:active {
  background:#0afbda;
  -webkit-box-shadow:0 0 7px 0 #0afbda;
  box-shadow:0 0 7px 0 #0afbda
}
.payment-item:hover {
  -webkit-box-shadow:0 0 12px 3px #0afbda;
  box-shadow:0 0 12px 3px #0afbda
}
.payment-item__image {
  display:block;
  margin:0 auto;
  width:auto
}
@media only screen and (max-width:960px) {
  .payment-box {
    -ms-flex-wrap:wrap;
    flex-wrap:wrap
  }
}
@media only screen and (max-width:480px) {
  .payment-item {
    width:100%;
    height:140px;
    margin:0 auto
  }
  .cash-box-container .cabinet-action {
    margin:0
  }
}