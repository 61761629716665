$screen-sm-max: 576px;

$screen-md-max: 767px;

$screen-lg-min: 992px;

$screen-xl-max: 1200px;

@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$screen-xl-max}) {
        @content;
    }
}